.navbar {
    background-color: #053544;
}

.logo {
    width: 200px;
    max-height: 3rem !important;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;

    @media only screen and (min-width: 768px) {
        max-height: 4rem !important;
    }
}

.menu {
    @media only screen and (max-width: 768px) {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

.link {
    -webkit-user-select: none;
    user-select: none;
}