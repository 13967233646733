.photo {
    height: 32px;
}

.button {
    padding: 0.1em 0.25em !important;

    &::after {
        display: none !important;
    }
}