.previewItem {
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
    
    &:hover {
        background-color: #e9e9e9;
    }

    .imgPreview {
        width: 64px;
        text-align: center;

        img {
            height: auto;
            max-width: 100%;
            max-height: 64px;
        }
    }
}