textarea, .formControl {
    border: 1px solid var(--macro-bright-blue) !important;

    &:focus {
        border: 1px solid var(--macro-bright-blue-40) !important;
        box-shadow: 0 0 0 0.2rem var(--macro-bright-blue-20)!important;
    }

    &:disabled {
        cursor: not-allowed !important;
    }
}

.formControl[type="date"] {
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    line-height: normal !important;

    &::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: pointer;
    }
}

.invalid {
    border-color: var(--macro-red) !important;
}