.tile {
    width: 75%;
    margin-left: 12.5%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    -moz-aspect-ratio: 1;
    -webkit-aspect-ratio: 1;
    aspect-ratio: 1;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;

    .tileContent {
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        width: 100%;
        height: 100%;
        position: absolute;
        top: calc(100% - 100px);
        color: white;
        padding-top: .25em;
        padding-left: .5em;
        transition: top .5s ease-in, background-color .5s ease-in;

        .title {
            font-size: 1.5rem;
            margin: 0;
            padding: 0;
        }

        .description {
            font-size: 1rem;
            margin: 0;
            padding: 0;
        }
    }

    &:hover {
        .tileContent {
            background-color: rgba(0, 0, 0, 0.8);
            top: 50px;
        }
    }

    &:active {
        .tileContent {
            background-color: rgba(0, 0, 0, 0.9);
            top: 45px;
        }
    }
}