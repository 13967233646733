.authoriseMain {
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
}

.authorise {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    transition: background-color 1s ease-in-out;

    &.fade {
        background-color: rgba(0, 0, 0, 0.25);
    }
}