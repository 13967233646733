.dropzone {
    display: flex;
    height: 100px;
    width: 100%;
    align-items: center;
    border-width: var(--bs-border-width);
    border-style: dashed;
    border-color: var(--macro-bright-blue);
    border-radius: var(--bs-card-border-radius);
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: background-color 0.3s ease, color 0.3s ease;

    p {
        width: 100%;
        text-align: center;
        margin-bottom: 0;
    }

    &:hover {
        background-color: var(--macro-bright-blue-20);
    }

    &:active {
        background-color: var(--macro-bright-blue-60);
        color: white;
    }

    &.disabled {
        background-color: var(--macro-dark-blue-20);
    }
}

.previews {
    list-style-type: none;
    padding: 0;
    margin: 0;
}