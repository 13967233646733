.button {
    padding: .625rem .75rem;

    .label {
        display: block;
        font-size: 14px;
        color: rgba(var(--bs-body-color-rgb),.65) !important;
        pointer-events: none;
    }
    
    .value {
        display: block;
        color: #000 !important;
        font-size: var(--bs-body-font-size);
        font-weight: 500;
    }
    
    .icon { 
        color: #000;
        font-size: 14px;
    }

    &:hover {
        background-color: transparent !important;
        cursor: initial !important;
    }

    &:disabled {
        background-color: var(--bs-secondary-bg) !important;
        cursor: not-allowed !important;
    }
}