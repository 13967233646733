@media screen and (min-width: 768px) {
    .cell {

        .flex {
            height: 100%;
            display: flex;
            align-items: center;

            .block {
                display: block;
            }
        }

        &:not(:last-of-type) {
            border-width: 1px;
            border-right-style: solid;
            border-color: var(--macro-warm-grey-80);
        }
    }
}

@media screen and (max-width: 767px) {
    .cell {
        margin-bottom: .25rem;
    }
}